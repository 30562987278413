import React, { Component } from "react"
import ReactDOM from "react-dom"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { Row, Col } from "react-flexbox-grid"
import "./index.scss"
import Hero from "../../../components/Hero"
import Layout from "../../../components/Layout"
import ContentBlock from "../../../components/ContentBlock"
import HelmetWrapper from "../../../components/HelmetWrapper"
import ExternalLink from "../../../components/ExternalLink"
import Interstitial from "../../../components/ExternalInterstitial/Interstitial"
import CalloutBlock from "../../../components/CalloutBlock"
import arrow from "../../../assets/images/chevron-circle-right-solid.svg"

import icon1 from "../../../assets/images/Icon1.png";
import icon2 from "../../../assets/images/Icon2.png"
import icon3 from "../../../assets/images/Icon3.png"
import icon4 from "../../../assets/images/Icon4.png"
import icon5 from "../../../assets/images/Icon5.png"
import researchHero from "../../../assets/images/research-hero.png"
import headerHero from "../../../assets/images/hero_science2.webp"
import tempoLogo from "../../../images/TempoLogo.png"
import reconnectLogo from "../../../images/ReconnectLogo.png"
import smCircleHBS from "../../../assets/images/HBS-102-Icon-FNL.webp"

const pageTitle = "Research Focus Areas | Harmony Biosciences"
const nct1Tracking = {
  category: "ExternalLink",
  action: "click",
  label: "NCT04257929",
}
const nct2Tracking = {
  category: "ExternalLink",
  action: "click",
  label: "NCT04886518",
}
const pageDescription =
  "Learn more about our research focus areas: Fragile X syndrome, idiopathic hypersomnia, myotonic dystrophy Type 1, Prader-Willi syndrome and 22q deletion syndrome"
const pageKeywords = ""

class focusAreas extends Component {
  constructor() {
    super()
  }

  peaceLinkClick = () => {
    const isGA = typeof window.ga !== "undefined"

    if (isGA) {
      window.ga(
        "send",
        "event",
        "External Link",
        "click",
        "thepeacenarcolepsyprogram.com"
      )
    }
    window.open("https://thepeacenarcolepsyprogram.com/", "_blank")
  }

  componentDidMount() {
    if (this.props.location) {
      const id = this.props.location.hash?.replace('#', '')
      if (id) {

        // initially scroll to the top of the page
        setTimeout(()=>{
          window.scrollTo({ top: 0 })
        })
        // After 300ms smoothly scroll to the particular section
        setTimeout(() => {
          const elem = document.getElementById(id);
          if (elem) {
            const elemPos = elem.getBoundingClientRect().top + window.scrollY;
            const offset = elemPos - 150;
            window.scrollTo({
              top: offset,
              behavior: 'smooth',
            })
          }
        }, 300)
      }
    }
  }

  render() {
    return (
      <Layout jobCode="US-HAR-2200062/Aug 2022">
        <div id="research-areas">
          <HelmetWrapper
            pageTitle={pageTitle}
            pageDescription={pageDescription}
            pageKeywords={pageKeywords}
            slug="/patients/research-focus-areas"
          />
          <Hero col={7} bg={researchHero}>
            <h1>Research Focus Areas</h1>
          </Hero>

          <div>
            <div className="areas-content-block">
              <ContentBlock color="white" className="last">
                <Row className={"callout-row"}>
                  <Col xs={12} md={5}>
                    <AnchorLink
                      className="no-underline"
                      offset="150"
                      href="#fragile-x"
                    >
                      <CalloutBlock className="text-only bg-gray">
                        <AnchorLink offset="150" href="#fragile-x">
                          <h2>
                            <strong>FRAGILE X SYNDROME</strong>
                          </h2>
                        </AnchorLink>
                        <img className="callout-arrow newboy" src={arrow} />
                      </CalloutBlock>
                    </AnchorLink>
                  </Col>

                  <Col xs={12} md={5}>
                    <AnchorLink
                      className="no-underline"
                      offset="150"
                      href="#idiopathic-hypersomnia"
                    >
                      <CalloutBlock className="text-only bg-gray">
                        <AnchorLink offset="150" href="#idiopathic-hypersomnia">
                          <h2>
                            <strong>IDIOPATHIC HYPERSOMNIA</strong>
                          </h2>
                        </AnchorLink>
                        <img className="callout-arrow newboy" src={arrow} />
                      </CalloutBlock>
                    </AnchorLink>
                  </Col>
                </Row>

                <Row className={"callout-row"}>
                  <Col xs={12} md={5}>
                    <AnchorLink
                      className="no-underline"
                      offset="150"
                      href="#myotonic-dystrophy"
                    >
                      <CalloutBlock className="text-only bg-gray">
                        <AnchorLink offset="150" href="#myotonic-dystrophy">
                          <h2>
                            <strong>MYOTONIC DYSTROPHY TYPE 1</strong>
                          </h2>
                        </AnchorLink>
                        <img className="callout-arrow newboy" src={arrow} />
                      </CalloutBlock>
                    </AnchorLink>
                  </Col>
                  <Col xs={12} md={5}>
                    <AnchorLink
                      className="no-underline"
                      offset="150"
                      href="#prader-willi"
                    >
                      <CalloutBlock className="text-only bg-gray">
                        <AnchorLink offset="150" href="#prader-willi">
                          <h2>
                            <strong>PRADER-WILLI SYNDROME</strong>
                          </h2>
                        </AnchorLink>
                        <img className="callout-arrow newboy" src={arrow} />
                      </CalloutBlock>
                    </AnchorLink>
                  </Col>
                </Row>

                <Row className={"callout-row"}>
                  <Col xs={12} md={5}>
                    <AnchorLink
                      className="no-underline"
                      offset="150"
                      href="#deletion-syndrome"
                    >
                      <CalloutBlock className="text-only bg-gray">
                        <AnchorLink offset="150" href="#deletion-syndrome">
                          <h2>
                            <strong>22q11.2 DELETION SYNDROME</strong>
                          </h2>
                        </AnchorLink>
                        <img className="callout-arrow newboy" src={arrow} />
                      </CalloutBlock>
                    </AnchorLink>
                  </Col>
                </Row>
              </ContentBlock>
            </div>
            <ContentBlock id="fragile-x" color="green">
              <Row>
                <Col xs={12} md={3}>
                  <Row center="xs">
                    <Col>
                      <img src={icon1} className="" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={9}>
                  <h2>FRAGILE X SYNDROME</h2>
                  <p>
                    Fragile X syndrome (FXS), originally known as Martin-Bell
                    syndrome, is a rare genetic condition that causes
                    intellectual disability, anxiety, social avoidance,
                    behavioral and learning challenges, and various physical
                    characteristics. It is the leading known genetic cause of
                    both inherited intellectual disability and autism spectrum
                    disorder.
                  </p>
                  <p>
                    FXS patients are born with a mutation in a gene called{" "}
                    <em>FMR1</em>, which leads to dysregulation of the
                    endocannabinoid (EC) system and may result in the core
                    cognitive, social, and behavioral symptoms seen in FXS. The
                    impairment can range from learning disabilities to severe
                    cognitive or intellectual disabilities. Patients with FXS
                    exhibit autism-like symptoms including social anxiety and
                    avoidance, cognitive impairment, intellectual disability,
                    mood swings, attention deficiency, irritability, and
                    increased risk for aggression.
                  </p>
                  <p>
                    An estimated 80,000 patients in the US are believed to have
                    FXS, based on FXS prevalence estimates of approximately 1 in
                    4,000 to 7,000 in males and of approximately 1 in 8,000 to
                    11,000 in females.
                  </p>
                </Col>
              </Row>
            </ContentBlock>

            <ContentBlock id="idiopathic-hypersomnia" color="blue">
              <Row>
                <Col xs={12} md={3}>
                  <Row center="xs">
                    <Col>
                      <img src={icon2} className="" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={9}>
                  <h2>IDIOPATHIC HYPERSOMNIA</h2>
                  <p>
                    Idiopathic hypersomnia (IH) is a rare and debilitating sleep
                    disorder that can significantly disrupt daily functioning.
                    IH is characterized by excessive daytime sleepiness (EDS)
                    despite sufficient or even long sleep time. Other symptoms
                    include unrefreshed sleep, difficulty in awakening,
                    cognitive dysfunction, and autonomic symptoms.
                  </p>
                  <p>
                    IH is usually not alleviated by naps, longer sleep, or more
                    efficient sleep. People living with IH experience
                    significant EDS along with prolonged difficulty waking up
                    from sleep and “brain fog.” While the cause of IH is
                    unknown, many patients have a family history of similar
                    symptoms, and IH is likely due to alterations in areas of
                    the brain that stabilize states of sleep and wakefulness.
                  </p>
                  <p>
                    Approximately 80,000 people in the US are believed to be
                    affected by IH, with 40,000 currently having been diagnosed.
                  </p>
                </Col>
              </Row>
            </ContentBlock>

            <ContentBlock id="myotonic-dystrophy" color="orange">
              <Row>
                <Col xs={12} md={3}>
                  <Row center="xs">
                    <Col>
                      <img src={icon3} className="" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={9}>
                  <h2>MYOTONIC DYSTROPHY TYPE 1</h2>
                  <p>
                    Myotonic dystrophy type 1 (DM1) is a neuromuscular disorder
                    that causes muscle weakness and is the most common form of
                    adult-onset muscular dystrophy. It is an inherited disorder
                    that is caused by a genetic mutation, and onset often occurs
                    in an individual’s 20s and 30s. DM1 also affects multiple
                    systems including the heart, eyes, endocrine system, and
                    central nervous system.
                  </p>
                  <p>
                    Latest estimates suggest a prevalence of about 1 per 2,100
                    people with the genetic defect for DM1, which equates to
                    about 150,000 people in the US. Estimates suggest there are
                    40,000 people currently diagnosed with DM1 in the US, with
                    up to 90% of them reporting excessive daytime sleepiness
                    (EDS) and fatigue and over 60% of them experiencing
                    cognitive dysfunction.{" "}
                  </p>
                </Col>
              </Row>
            </ContentBlock>
            <ContentBlock id="prader-willi" color="green">
              <Row>
                <Col xs={12} md={3}>
                  <Row center="xs">
                    <Col>
                      <img src={icon4} className="" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={9}>
                  <h2>PRADER-WILLI SYNDROME</h2>
                  <p>
                    Prader-Willi syndrome (PWS) is a rare genetic neurological
                    disorder with many of the symptoms resulting from
                    hypothalamic dysfunction.
                  </p>
                  <p>
                    The hypothalamus is an area of the brain that controls both
                    sleep-wake state stability and signals that mediate the
                    balance between hunger and satiety, resulting in the main
                    symptoms in patients with PWS: hyperphagia (an intense
                    persistent sensation of hunger accompanied by food
                    preoccupations, an extreme drive to consume food,
                    food-related behavior problems, and a lack of normal
                    satiety); excessive daytime sleepiness (EDS); and behavioral
                    symptoms. Other features include low muscle tone, short
                    stature, and cognitive impairment.
                  </p>
                  <p>
                    Approximately 15,000 to 20,000 people in the US are living
                    with PWS, the majority experiencing behavioral symptoms and
                    more than half with EDS. There is currently no FDA-approved
                    treatment for EDS in this patient population.
                  </p>
                </Col>
              </Row>
            </ContentBlock>

            <ContentBlock id="deletion-syndrome" color="blue">
              <Row>
                <Col xs={12} md={3}>
                  <Row center="xs">
                    <Col>
                      <img src={icon5} className="" />
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={9}>
                  <h2>22q11.2 DELETION SYNDROME</h2>
                  <p>
                    22q11.2 deletion syndrome (22q) is a disorder caused by a
                    small missing piece of the 22nd chromosome. The deletion
                    occurs near the middle of the chromosome at a location
                    designated q11.2. It is considered a midline condition, with
                    physical symptoms including characteristic palate
                    abnormalities, heart defects, immune dysfunction, and
                    esophageal/GI issues, as well as debilitating
                    neuropsychiatric and behavioral symptoms, including anxiety,
                    irritability, social withdrawal, ADHD, cognitive impairment,
                    and autism spectrum disorder.
                  </p>
                  <p>
                    It is estimated that 22q occurs in 1 in 4,000 live births,
                    suggesting that there are approximately 80,000 people living
                    with 22q in the US, and there are currently no FDA-approved
                    treatments for this disorder.
                  </p>
                </Col>
              </Row>
            </ContentBlock>
          </div>

          <Interstitial />
        </div>
      </Layout>
    )
  }
}

export default focusAreas
